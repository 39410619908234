<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Copyright  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.kompozition.com"
        target="_blank"
      >Kompozition_</b-link>
      <span class="d-none d-sm-inline-block">, All Rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block font-small-3">
      Release {{ release }}
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import getEnv from '@/utils/env'

export default {
  components: {
    BLink,
  },
  data: () => ({
    release: getEnv('VUE_APP_KOMPOZITION_RELEASE'),
  }),
}
</script>
